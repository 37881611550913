<template lang="html">
    <v-text-field
        ref="input"
        :value="formattedValue"
        @change="change"
        v-on="listeners"
        v-bind="attrs"
        :suffix="suffix"
        :prefix="prefix"
        class="v-money" />
</template>

<script>
import money from './directive'
import defaults from './options'
import { format, unformat } from './utils'

export default {
    name: 'Money',
    props: {
        value: {
            required: false,
            type: [ Number, String, null, undefined ],
            default: () => 0
        },
        masked: {
            type: Boolean,
            default: () => false
        },
        precision: {
            type: Number,
            default: () => defaults.precision
        },
        decimal: {
            type: String,
            default: () => defaults.decimal
        },
        thousands: {
            type: String,
            default: () => defaults.thousands
        },
        prefix: {
            type: String,
            default: () => defaults.prefix
        },
        suffix: {
            type: String,
            default: () => defaults.suffix
        }
    },

    directives: { money },

        data() {
            return {
                formattedValue: '',
            }
        },

    watch: {
        value: {
            immediate: true,
            handler(newValue, oldValue) {
                var formatted = format(newValue, this.$props)
                if (formatted !== this.formattedValue) {
                    this.formattedValue = formatted
                }
                this.change(formatted)
            }
        }
    },

    methods: {
        change(value) {
            this.$emit(
                'input',
                this.masked ? value : unformat(value, this.precision)
            )
        },
        focus() {
            this.$refs['input'].focus()
        }
    },

    computed: {
        listeners() {
            const { change, ...listeners } = this.$listeners
            return listeners
        },
        attrs() {
            // Remove rules das props pois gera erros ao considerar o valor mask
            const { ...attrs } = this.$attrs
            return { ...attrs }
        },
        'v-money-config'() {
            return {
                precision: this.precision,
                decimal: this.decimal,
                thousands: this.thousands,
                suffix: this.prefix,
                prefix: this.suffix,
            }
        },
    }
}
</script>

<style lang="scss">
// Corrige o prefix
.v-money {
    .v-text-field__prefix {
        width: 30px;
        text-align: left;
    }
}
</style>
