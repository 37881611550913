var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-text-field",
    _vm._g(
      _vm._b(
        {
          ref: "input",
          staticClass: "v-money",
          attrs: {
            value: _vm.formattedValue,
            suffix: _vm.suffix,
            prefix: _vm.prefix
          },
          on: { change: _vm.change }
        },
        "v-text-field",
        _vm.attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }