<template>
    <BaseInput :label="attrs.label" :disabled="attrs.disabled" :hideLabel="hideLabel">
        <template #input>
            <money 
                ref="input" 
                solo flat
                v-bind="attrs" 
                v-on="listeners"
            />
        </template>
    </BaseInput>
</template>

<script>
import BaseInput from '@/shared/components/inputs/BaseInput'
import MathUtils from '@/utils/mathUtils'
import { Money } from '@/utils/v-money'

export default {
    name: 'CurrencyInput',
    inheritAttrs: false,
    props: {
        prefixed: {
            type: Boolean,
            default: false,
        },
        suffixed: {
            type: Boolean,
            default: false,
        },
        hideLabel: {
            type: Boolean,
            default: false,
        }
    },
    components: { 
        BaseInput,
        'money': Money,
    },
    // props: {
    //     value: [Number, String],
    //     max: {
    //         required: false,
    //         type: [Number, Object] // Pode ser null
    //     },

    //     // Indica se o componente deve ficar vermelho quando os valores são negativos
    //     color: Boolean,
    // },
    data: vm => ({
        moneyConfig: {
            decimal: ',',
            thousands: '.',
            prefix: vm.prefixed ? vm.$t('forms.GC_CommonForm.value.prefix') : '',
            suffix: vm.suffixed ? vm.$t('forms.GC_CommonForm.amount.suffix') : '',
            precision: 2,
            masked: false
        },
    }),
    // methods: {
    //     digit(n) {
    //         if (n == this.moneyConfig.decimal) {
    //             this.comma()
    //             return
    //         }

    //         // [TODO] Atenção: Prestar atenção quando mudar o número de casas decimais para diferente de 2
    //         var numberAsString = MathUtils.roundNumber(this.value, this.moneyConfig.precision)
    //         this.input(numberAsString + n)
    //     },
    //     backspace() {
    //         var rounded = MathUtils.roundNumber(this.value, this.moneyConfig.precision)
    //         var numberAsString = rounded.slice(0, -1)
    //         this.input(numberAsString)
    //     },
    //     comma() {
    //         var numberAsString = MathUtils.roundNumber(this.value, this.moneyConfig.precision)

    //         // Remove os pontos e vírgulas atuais
    //         numberAsString = numberAsString.replace(/\D/g, '')

    //         // Coloca o ponto no final
    //         numberAsString += '.'

    //         this.input(Number(numberAsString))
    //     },
    //     clear() {
    //         this.input(0)
    //     },
        // input(newValue) {
        //     this.$emit('input', newValue)
        // },
    //     set(n) {
    //         this.input(n)
    //     },
    //     add(n) {
    //         this.input(this.value + n)
    //     },
    //     focus() {
    //         this.$refs['input'].focus()
    //     }
    // },
    computed: {
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { ...attrs } = this.$attrs
            return { ...attrs, ...this.moneyConfig }
        },
        // moneyConfig() {
        //     return this.$locale.getCurrencyConfig()
        // }
    },
    // watch: {
    //     value: {
    //         immediate: true,
    //         handler (newValue, oldValue) {
    //             var localValue = newValue > this.max ? this.max : newValue
    //             if (localValue !== this.localValue)
    //                 this.localValue = localValue
    //         }
    //     }
    // }
}
</script>
